@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--matterColorLight);
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  height: calc(80vh - 60px);
  padding: 0;
  background-color: var(--matterColorLight);

  @media (orientation: landscape) {
    height: 100%;
  }

  @media (--viewportMedium) {
    height: calc(80vh - 72px);
    min-height: 350px;
  }
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
  padding: 5px 5px 0 5px;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorNotSoBright);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    top: -20px;
    opacity: 0;
  }
  100% {
    visibility: visible;
    top: 0;
    opacity: 1;
  }
}

.guarantee {
  composes: h4 from global;
  background-color: var(--footerColor);
  padding: 10px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  margin-top: -1px;

  @media (--viewportMedium) {
    composes: marketplaceBodyFontStyles from global;
    margin-top: -26px;
    margin-left: calc(50vw - 225px);
    width: 450px;
  }
}

.guarantee a {
  color: var(--matterColorLight);
}

.testimonial {
  padding: 5px 5px 0 5px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0 5px;
  }
}

.sectionTitle {
  composes: h1 from global;

  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.link {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 220px;
  display: inline-block;
}

.wideLink {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 240px;
  display: inline-block;
}

.learnMoreAboutHiring {
  text-align: center;
  margin-top: 40px;
}
